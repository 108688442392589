var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "community__cards" }, [
    _c("div", { staticClass: "o-wrapper" }, [
      _vm.showInitialPlaceholders || !!_vm.cards.length
        ? _c("div", { staticClass: "o-grid" }, [
            _c("div", { staticClass: "o-grid__item u-width-full" }, [
              _c(
                "div",
                {
                  staticClass: "cards__wrapper",
                  attrs: { id: "cardsWrapper" },
                },
                [
                  _vm.showInitialPlaceholders
                    ? _c("content-placeholders")
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "cards",
                      attrs: { id: "cards", "data-cy": "community-cards" },
                    },
                    [
                      _vm._l(_vm.cards, function (card) {
                        return _c("post-card-api", {
                          key: card.id,
                          attrs: { card: card },
                        })
                      }),
                      _vm._v(" "),
                      _vm._l(8, function (index) {
                        return _vm.showPagingPlaceHolders
                          ? [_c("content-placeholder")]
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.showNoResults
      ? _c("div", { staticClass: "search__no-results" }, [
          _c("div", [
            _c("h1", { domProps: { innerHTML: _vm._s(_vm.noResultsTitle) } }),
            _vm._v(" "),
            _c("p", { domProps: { innerHTML: _vm._s(_vm.noResultsContent) } }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }