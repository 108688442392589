<template>
    <div class="community__cards">
        <div class="o-wrapper">
            <div v-if="showInitialPlaceholders || !!cards.length" class="o-grid">
                <div class="o-grid__item u-width-full">
                    <div id="cardsWrapper" class="cards__wrapper">
                        <content-placeholders
                            v-if="showInitialPlaceholders"
                        ></content-placeholders>
                        <div
                            class="cards"
                            id="cards"
                            data-cy="community-cards"
                        >
                            <post-card-api
                                v-for="card in cards"
                                :key="card.id"
                                :card="card"
                            ></post-card-api>
                            <template v-for="index in 8" v-if="showPagingPlaceHolders">
                                <content-placeholder
                                ></content-placeholder>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showNoResults" class="search__no-results">
            <div>
                <h1 v-html="noResultsTitle"></h1>
                <p v-html="noResultsContent"></p>
            </div>
        </div>
    </div>
</template>

<script>
import PostCardApi from "../PostCardApi";
import MasonryStore from "../../configs/masonry-store";
import ContentPlaceholder from "../ContentPlaceholder";
import ContentPlaceholders from "../ContentPlaceholders";

export default {
    components: {
        PostCardApi,
        ContentPlaceholder,
        ContentPlaceholders
    },
    data() {
        return {
            cards: [],
            loading: false,
            loadedSession: false,
            masonry: MasonryStore,
            next: true,
            noResultsContent: 'Please try searching again using a combination of words or phrases.',
            noResultsTitle: 'No content found',
            page: 1,
            scrollTo: 0,
            showInitialPlaceholders: false,
            showNoResults: false,
            showPagingPlaceHolders: false,
            type: 'all'
        };
    },
    watch: {
        $route(to, from) {
            this.cards = [];
            this.page = 1;
            this.fetchData();
            this.masonry.load();
        },
    },
    created() {
        this.$root.$on('bus-CommunityCards-initial-placeholder', () => {
            this.blankCards();
            this.showInitialPlaceholders = true;
            this.masonry.images();
        });
        this.$root.$on('bus-CommunityCards-paging-placeholders', () => {
            this.showInitialPlaceholders = false;
            this.showPagingPlaceHolders = true;
            this.masonry.images();
        });
        // update content cards on hashchange
        window.addEventListener(
            'hashchange',
            () => {
                let hash = this.$simpleStore.getHash(true);
                if (hash.length > 1) {
                    this.resetCards();
                }
            },
            false
        );
    },
    mounted() {
        // fetch the data when the view is created and the data is
        // already being observed
        if (this.$root.$route.name !== 'contentSearch') {
            this.fetchData();
            this.scrollPosts();
        }
        this.$root.$on('bus-CommunityCards-update', this.updateCards);
        this.$root.$on('bus-CommunityCards-add', this.addCards);
        this.$root.$on('bus-CommunityCards-reset', this.resetCards);
        this.$root.$on('bus-CommunityCards-blank', this.blankCards);
        console.log(this.$route.name);
    },
    updated() {
        //async(onYouTubeIframeAPIReady);
        this.$nextTick(() => {
            if (this.loadedSession && this.scrollTo > 0) {
                window.onload = () => {
                    // wait 250 ms to allow Isotope to reload
                    setTimeout(() => {
                        window.scrollTo(0, this.scrollTo);
                    }, 250);
                    this.loadedSession = false;
                };
            }
        });
    },
    methods: {
        showMemberPanel() {
            this.$simpleStore.showMemberPanel(this);
        },
        getSessionName() {
            let hashtag = this.$simpleStore.getHash(true);
            return (
                'c' + '-' + this.type + '-' +
                this.$root.community.id +
                (hashtag.length > 1 ? '-h-' + hashtag : '')
            );
        },
        getSession(resetSession = false) {
            if (this.$root.$route.name === 'contentSearch') {
                return [];
            }
            const currentTimestamp = Math.floor(Date.now() / 1000);
            let sessionName = this.getSessionName();
            let session = sessionStorage.getItem(sessionName)
                ? JSON.parse(sessionStorage.getItem(sessionName))
                : null;
            let timestamp = session != null ? session[0].timestamp : 0;
            //console.log((currentTimestamp - timestamp), currentTimestamp, timestamp, this.$simpleStore.sessionTTL);
            if (
                resetSession === true ||
                timestamp == null ||
                currentTimestamp - timestamp > this.$simpleStore.sessionTTL
            ) {
                sessionStorage.removeItem(sessionName);
                return [
                    {
                        cards: [],
                        timestamp: 0,
                        page: 1,
                        scroll: 0,
                    },
                ];
            }
            return session;
        },
        setSession() {
            if (this.$root.$route.name === 'contentSearch') {
                return;
            }
            const currentTimestamp = Math.floor(Date.now() / 1000);
            let session = [
                {
                    cards: [],
                    timestamp: 0,
                    page: 0,
                    scroll: 0,
                },
            ];
            let sessionName = this.getSessionName();
            if (sessionStorage.getItem(sessionName)) {
                session = JSON.parse(sessionStorage.getItem(sessionName));
            }
            for (let i = 0; i < this.cards.length; i++) {
                let postCard = {};
                for (let j in this.cards[i]) {
                    if (this.cards[i][j] != null) {
                        postCard[j] = this.cards[i][j];
                    }
                }
                if (
                    session[0].cards == null ||
                    session[0].cards.find(
                        (x) => x.dyadey_id === postCard.dyadey_id
                    ) == null
                ) {
                    try {
                        session[0].cards.push(postCard);
                    } catch (err) {
                        console.log('Error:');
                        console.log(err);
                    }
                }
            }
            if (session[0].timestamp === 0) {
                session[0].timestamp = currentTimestamp;
            }
            session[0].page = this.page;
            sessionStorage.setItem(sessionName, JSON.stringify(session));
        },
        setSessionScroll(scrollHeight) {
            let sessionName = this.getSessionName();
            if (sessionStorage.getItem(sessionName)) {
                let session = JSON.parse(sessionStorage.getItem(sessionName));
                session[0].scroll = scrollHeight;
                sessionStorage.setItem(sessionName, JSON.stringify(session));
            }
        },
        fetchData(resetSession = false) {
            if (resetSession === true) {
                this.cards = null;
            }
            switch (this.$root.$route.name) {
                case 'communityOfficialPosts':
                    this.type = 'official';
                    break;

                case 'communityArticlePosts':
                    this.type = 'articles';
                    break;
            }
            let session = this.getSession(resetSession);
            // if there are valid cards in the session display them and do no requests
            if (this.page === 1 && session[0].cards.length > 0) {
                // scroll to previous position from session data using update() function
                this.loadedSession = true;
                this.scrollTo = session[0].scroll;
                this.cards = session[0].cards;
                this.page = session[0].page;
                this.masonry.images();
                return;
            }
            let params = {
                page: this.page,
                type: this.type
            };
            if (typeof this.$route.params.community != 'undefined') {
                params.community_url = this.$route.params.community;
            }
            let hashTag = this.$simpleStore.getHash(true);
            if (hashTag.length > 1) {
                params.hashtag = hashTag;
            }
            this.loading = true;
            if (this.page === 0) {
                this.showInitialPlaceholders = true;
                this.masonry.images();
            } else {
                this.showPagingPlaceHolders = true;
                this.masonry.images();
            }
            this.$http.post('/api/v1/posts', params).then((response) => {
                if (this.cards == null) {
                    this.cards = response.data.cards;
                } else {
                    this.cards.push.apply(this.cards, response.data.cards);
                }
                this.showNoResults = this.cards.length === 0;
                if (this.$route.name !== 'contentSearch') {
                    this.noResultsTitle = 'No articles added';
                    this.noResultsContent = 'No articles have been added to this community yet.';
                }
                this.showInitialPlaceholders = false;
                this.showPagingPlaceHolders = false;
                this.next = this.cards.length > 0;
                this.page++;
                this.loading = false;
                this.setSession();
                this.masonry.images();
            },
            (response) => {
                console.log('Errored:');
                console.log(response);
            });
        },
        scrollPosts() {
            let isScrolling;
            window.addEventListener('scroll', () => {
                // if there is an active content search do not scroll
                if (
                    typeof this.$root.search != 'undefined' &&
                    this.$root.search.length > 0
                ) {
                    return;
                }
                let scrollHeight = Math.max(
                    window.scrollY,
                    document.documentElement.scrollTop,
                    document.body.scrollTop
                );
                window.clearTimeout(isScrolling);
                // Only update session scroll after scrolling ends
                isScrolling = setTimeout(() => {
                    this.setSessionScroll(scrollHeight);
                }, 66);
                // only calculate scroll height when not loading and when there are more posts to load
                if (this.loading === false && this.next) {
                    let percentageScroll =
                        (scrollHeight + window.innerHeight) /
                        document.documentElement.offsetHeight;
                    // load more posts when scrolled 90% of screen height
                    if (percentageScroll > 0.9) {
                        this.fetchData();
                    }
                }
            });
        },
        resetCards() {
            this.page = 1;
            this.fetchData(true);
            this.masonry.load();
        },
        blankCards() {
            this.cards = [];
            this.masonry.load();
        },
        updateCards(data) {
            this.cards = [];
            this.next = data.cards.length > 0;
            this.page = data.page;
            this.showInitialPlaceholders = false;
            this.cards.push.apply(this.cards, data.cards);
            this.masonry.images();
        },
        addCards(data) {
            this.showInitialPlaceholders = false;
            if (data.cards.length === 0) {
                this.showNoResults = true;
            } else {
                this.cards.push.apply(this.cards, data.cards);
                this.masonry.images();
            }
        },
    },
};
</script>
